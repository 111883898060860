
/*
* Modal form plugin
* Captures a form submit converts it to an ajax post request then
* replaces the form with the response of the post request
* Depends:
*   jquery.ui.core.js
*   jquery.ui.widget.js
*/

/* eslint no-unused-vars: 0 */
(function ($) {

    var errorIframeTemplate = '<a class="close modal-close" href="javascript:void(0);" style="position: absolute;top: 3px;right: 9px;color: var(--tui-sem-color-text-tertiary)!important;">×</a><iframe src="{SOURCE}" style="width: 580px; height: 480px; border: none; margin-bottom:-7px"></iframe>';

    $.widget("nl.modalForm", {

        overlay : null,

        options: {
            outputSelector: '',
            selfClose: false,
            onClose: null,
            onProcessFormValues: null,
            verb: 'POST',
            onCompleteCloseDelay : 500
        },

        _create: function () {
            var self = this;
            self.verb = self.options.verb;
            self.container = self.element;
            self.theForm = $('form', self.element);
            self.modalBody = $('.modal-body', self.element);
            self.modalFooter = $('.modal-footer', self.element);
            self.button = $('button[type=submit]', self.theForm);
            self.url = self.theForm.attr('action');
            self.validationFunctions = [];

            $(document.body).off(".modalForm");

            $(document.body).on('click.modalForm', 'a.submitter', function (e) {
                e.preventDefault();
                self.url = $(this).attr('data-href');
                if (!self.url || self.url.length == 0) self.url = $(this).attr('href');
                self.submitForm();
            });

            self.button.click(function (e) {
                e.preventDefault();
                if (self.button.css('display') == 'none' && $('.btn-primary.pop', self.theForm).length > 0) {
                    return;
                }

                self.submitForm(e);
            });
        },

        manualSubmit: function(options) {
            var self = this;
            if (options.formSelector) self.theForm = $(options.formSelector, self.container);
            if (options.url) self.url = options.url;
            if (options.verb) self.verb = options.verb;
            self.submitForm();
        },

        pushValidation: function(func) {
            this.validationFunctions.push(func);
        },

        isValid: function() {
            for (let i = 0; i < this.validationFunctions.length; i++) {
                if (!this.validationFunctions[i]()) {
                    return false;
                }
            }
            return true;
        },

        submitForm: function () {
            var self = this;

            if (!self.isValid()) return;

            var formVals = self.theForm.serializeArray();

            if (self.options.onProcessFormValues != null && typeof(self.options.onProcessFormValues) == 'function') {
                self.options.onProcessFormValues(formVals);
            }

            $(document).modal('resetModified');
            $(document).balloon('popBalloon');

            // using the enter key to click the save button still happens behind the overlay, so only submit if there is no overlay
            if (self.overlay != null && self.overlay.is(':visible')) {
                return;
            }

            self.container.parent().showLoading({
                afterShow: (callbackOptions) => { self.overlay = callbackOptions.overlay }
            });

            $.ajax({
                type: self.verb,
                url: self.url,
                data: formVals,
                success: function (data, textStatus, XMLHttpRequest) {
                    //Check if we need to redirect to the login screen
                    if (data && data.indexOf("TimelyLoggedOutSite") !== -1) {
                        window.location = '/Account/Login';
                    }
                    else if (data && data.indexOf("TimelyErrorPage") !== -1 && data.indexOf("TimelyUnauthorisedPage") === -1) {
                        self.container.parent().hideLoading();
                        self.container.parent().html(errorIframeTemplate.replace("{SOURCE}", '/error'));
                    } else if (data.indexOf("TimelyUnauthorisedPage") !== -1) {
                        self.container.parent().hideLoading();
                        self.container.parent().html(data);
                    }
                    else {

                        if (window.ReactDOM) {
                            $.each(self.container.parent().find('.react-container-node'), function (key, containerNode) {
                                window.ReactDOM.unmountComponentAtNode(containerNode);
                            });
                        }

                        self.container.parent().hideLoading();
                        self.container.parent().html(data);

                        if (data == '' || $('form', $.parseHTML(data)).length == 0) {
                            if (self.options.selfClose) {
                                setTimeout(function() {
                                    $('.modal-backdrop').click();
                                }, self.options.onCompleteCloseDelay);
                            }
                            if (self.options.onClose != null && typeof(self.options.onClose) == 'function') {
                                self.options.onClose();
                            }
                        }
                    }
                },
                error: function () {

                    self.container.parent().hideLoading();
                    self.modalBody.html('<h2>Whoops... An error occured</h2><p>The Timely techbots have been notified. Please try again later.</p><br/>');
                    self.modalFooter.html('<a href="javascript:void(0);" class="btn btn-large modal-close"><i class="fa fa-reply"></i>&nbsp;Return</a>');

                },
                dataType: 'html'
            });

        },

        destroy: function () {
            this.button.unbind('click');
            this._trigger("destroy", { type: "destroy" }, { options: this.options });
            $.Widget.prototype.destroy.call(this);

        },

        _setOption: function (key, value) {
            $.Widget.prototype._setOption.apply(this, arguments);
        }

    });

})(jQuery);
